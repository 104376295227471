/* #region CUSTOM CLASSES */
.test-bg {
    background: turquoise;
}

.disable-resize {
    resize: none;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-not-allowed:hover {
    cursor: not-allowed !important;
}

.text-one-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-no-wrap,
.no-wrap {
    white-space: nowrap;
}

.underline {
    text-decoration: underline;
}

button.text-one-line {
    display: block;
}

.border-radius {
    border-radius: 4px;
}

svg.ui-fix {
    overflow: visible !important;
}

svg.iconfix > path,
svg.ui-fix > path {
    stroke-width: 1.3;
}
/* #endregion */

/* #region MARGIN AND PADDINGS */

.m-0 {
    margin: 0 !important;
}
.p-0 {
    padding: 0 !important;
}

.p-4px {
    padding: 4px;
}
.pl-4px {
    padding-left: 4px;
}
.pr-4px {
    padding-right: 4px;
}
.pt-4px {
    padding-top: 4px;
}
.pb-4px {
    padding-bottom: 4px;
}
.m-4px {
    margin: 4px;
}
.ml-4px {
    margin-left: 4px;
}
.mr-4px {
    margin-right: 4px;
}
.mt-4px {
    margin-top: 4px;
}
.mb-4px {
    margin-bottom: 4px;
}

.p-5px {
    padding: 5px;
}
.pl-5px {
    padding-left: 5px;
}
.pr-5px {
    padding-right: 5px;
}
.pt-5px {
    padding-top: 5px;
}
.pb-5px {
    padding-bottom: 5px;
}
.m-5px {
    margin: 5px;
}
.ml-5px {
    margin-left: 5px;
}
.mr-5px {
    margin-right: 5px;
}
.mt-5px {
    margin-top: 5px;
}
.mb-5px {
    margin-bottom: 5px;
}

.p-8px {
    padding: 8px;
}
.pl-8px {
    padding-left: 8px;
}
.pr-8px {
    padding-right: 8px;
}
.pt-8px {
    padding-top: 8px;
}
.pb-8px {
    padding-bottom: 8px;
}
.m-8px {
    margin: 8px;
}
.ml-8px {
    margin-left: 8px;
}
.mr-8px {
    margin-right: 8px;
}
.mt-8px {
    margin-top: 8px;
}
.mb-8px {
    margin-bottom: 8px;
}

.p-10px {
    padding: 10px;
}
.pl-10px {
    padding-left: 10px;
}
.pr-10px {
    padding-right: 10px;
}
.pt-10px {
    padding-top: 10px;
}
.pb-10px {
    padding-bottom: 10px;
}
.m-10px {
    margin: 10px;
}
.ml-10px {
    margin-left: 10px;
}
.mr-10px {
    margin-right: 10px;
}
.mt-10px {
    margin-top: 10px;
}
.mb-10px {
    margin-bottom: 10px;
}

.p-15px {
    padding: 15px;
}
.pl-15px {
    padding-left: 15px;
}
.pr-15px {
    padding-right: 15px;
}
.pt-15px {
    padding-top: 15px;
}
.pb-15px {
    padding-bottom: 15px;
}
.m-15px {
    margin: 15px;
}
.ml-15px {
    margin-left: 15px;
}
.mr-15px {
    margin-right: 15px;
}
.mt-15px {
    margin-top: 15px;
}
.mb-15px {
    margin-bottom: 15px;
}

.p-16px {
    padding: 16px;
}
.pl-16px {
    padding-left: 16px;
}
.pr-16px {
    padding-right: 16px;
}
.pt-16px {
    padding-top: 16px;
}
.pb-16px {
    padding-bottom: 16px;
}
.m-16px {
    margin: 16px;
}
.ml-16px {
    margin-left: 16px;
}
.mr-16px {
    margin-right: 16px;
}
.mt-16px {
    margin-top: 16px;
}
.mb-16px {
    margin-bottom: 16px;
}

.p-24px {
    padding: 24px;
}
.pl-24px {
    padding-left: 24px;
}
.pr-24px {
    padding-right: 24px;
}
.pt-24px {
    padding-top: 24px;
}
.pb-24px {
    padding-bottom: 24px;
}
.m-24px {
    margin: 24px;
}
.ml-24px {
    margin-left: 24px;
}
.mr-24px {
    margin-right: 24px;
}
.mt-24px {
    margin-top: 24px;
}
.mb-24px {
    margin-bottom: 24px;
}
/* #endregion */

/* #region FLEX CLASSES */
.flex-container > .flex-row {
    margin-bottom: 8px;
}
.flex-container.gap16 > .flex-row {
    margin-bottom: 16px;
}
.flex-container > .flex-row:last-child {
    margin-bottom: 0px !important;
}

.flex {
    display: flex;
}
.flex-row {
    min-width: 0;
    display: flex;
    flex-wrap: nowrap;
}
.flex-row-break {
    flex-basis: 100%;
    height: 0;
}
.flex-col {
    min-width: 0;
    align-items: center;
    flex-basis: 100%;
}
.flex-basis-auto {
    flex-basis: auto;
}
.flex-1 {
    display: flex;
    flex-grow: 1;
    min-width: 0;
}
.flex-2 {
    display: flex;
    flex-grow: 2;
    min-width: 0;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

.gap-8px {
    gap: 8px;
}

.gap-16px {
    gap: 16px;
}

.flex-row > .flex-col {
    margin-right: 8px;
}
.flex-container.gap16 .flex-row > .flex-col,
.flex-row.gap16 > .flex-col {
    margin-right: 16px;
}
.flex-row > .flex-col:last-child {
    margin-right: 0px !important;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-content-start {
    justify-content: start;
}
.justify-content-end {
    justify-content: end;
}
.justify-content-center {
    justify-content: center;
}

.align-items-start {
    display: flex;
    align-items: start;
}
.align-items-end {
    display: flex;
    align-items: end;
}
.align-items-center {
    display: flex;
    align-items: center;
}
/* #endregion */

/* #region WIDTH AND HEIGHT CLASSES */
.w-100 {
    width: 100% !important;
}
.w-50 {
    width: 50% !important;
}
.h-100 {
    height: 100% !important;
}
.h-50 {
    height: 50% !important;
}

.overflow-visible,
.ov-visible {
    overflow: visible !important;
}
.overflow-hidden,
.ov-hidden {
    overflow: hidden !important;
}
/* #endregion */

/* #region POSITION CLASSES */
.float-right {
    float: right;
}

.float-left {
    float: left;
}

.text-centered,
.text-align-center {
    text-align: center;
}
.text-align-left {
    text-align: left;
}
.text-align-right {
    text-align: right;
}
/* #endregion */

/* #region OTHER HELPFUL CLASSES */
.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

/* #endregion */
